import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import { withTranslation } from 'react-i18next'
import WeAreSalona from '../sections/WeAreSalona/WeAreSalona'
import RediscoverYourHomeland from '../sections/RediscoverYourHomeland/RediscoverYourHomeland'
import OurCompany from '../sections/OurCompany/OurCompany'
import OurStory from '../sections/OurStory/OurStory'
import OurValues from '../sections/OurValues/OurValues'
import Header from '../sections/Header/Header'
import headerImg from '../assets/Salona_Consulting_Trogir-min.jpg'
import SEO from '../components/seo'
import '../i18n'
import '../styles/main.scss'
import OurMission from '../sections/OurMission/OurMission'
import i18n from '../i18n'

const IndexPage = ({t}) => {
  if (i18n.language !== 'en') {
    i18n.changeLanguage('en')
  }
  return (
    <Layout id='about'>
      <Header 
        image={headerImg}
        title={t('ABOUT_TITLE')}
        subtitle={t('ABOUT_SUBTITLE')}
        actionTitle={t('ABOUT_KNOW_MORE')}
        btnClass='btn-2'
        section={"rediscover-your-homeland"}
      />
      <SEO title={t("TITLE_ABOUT")} metaT={t("META_ABOUT")} />
      <RediscoverYourHomeland />
      <OurStory />
      <OurMission />
      <OurValues />
      <WeAreSalona />
      <OurCompany />
    </Layout>
  )
}

IndexPage.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(IndexPage)
